/**
 * developモードのみ develop.jsを取り込む
 */
ENV === 'development' ? require('develop') : null;

'use strict';
// smmothScroll
import '../modules/tr.smoothScroll.js';
// picturefill
import 'picturefill/dist/picturefill';
// modaal
import 'modaal/dist/js/modaal.min.js';
// accordion
import '../modules/accordion.js';

// resize
var flag = false;
var headerHeight = $('.header').innerHeight();

$(window).on('load resize', function() {
	flag = ( window.matchMedia('(max-width:767px)').matches );
	headerHeight = $('.header').innerHeight();
	$('.main').css({
		"padding-top": headerHeight + "px"
	});
} );

$(function Global () {

	var mql = window.matchMedia('screen and (max-width: 767px)');
	function checkBreakPoint(mql) {
		if (mql.matches) {// Mobile
			$('.header').removeClass('is-active');
		} else {// Desktop
			$('.header').removeClass('is-active');
		}
	}
	mql.addListener(checkBreakPoint);
	checkBreakPoint(mql);

	$('.sideBanner ._close button').on("click", function(){
		$('.sideBanner').remove();
	});
	$('.headerNavButton button').on("click", function(){
		$('.header').toggleClass('is-active');
	});
	$('.headerDocument a').on("click", function() {
		if (flag) {
			$('.header').toggleClass('is-active');
		}
	});
	$('.checkbox input').on("change", function(){
		$('.checkbox label').toggleClass('is-active');
	});

	$('.headerBusiness button').modaal({
		overlay_opacity: 0.5
	});
	$('.headerBusinessModal').modaal('close');
});

var documentHeight = $(document).height();
var contactHeight = $('.footer').innerHeight();
var scrollPositionTop = 0;
var scrollPositionBottom = 0;

$(window).on('scroll', function () {
	scrollPositionTop = $(window).scrollTop();
	scrollPositionBottom = $(window).height() + $(window).scrollTop();

	if (flag) {
		if (scrollPositionTop > 200 && documentHeight - scrollPositionBottom >= contactHeight) {
			$('.sideBanner').fadeIn(200);
		} else {
			$('.sideBanner').fadeOut(200);
		}
	} else {
		if (documentHeight - scrollPositionBottom <= contactHeight) {
			$('.sideBanner').fadeOut(200);
		} else {
			$('.sideBanner').fadeIn(200);
		}
	}

});

$(window).on('load', function () {
	var ankerList = $('a[href*="#"], area[href*="#"]');
	ankerList.each(function(){
		var href = $(this).attr("href");
		if(href.indexOf("#") === 0 || !href){
			$(this).smoothScroll({
				offset: -80,
				ignore: '[data-mfp]'
			});
		}
	});
});