/* Task Runner Smooth Scroll */

export default (function () {
	$.fn.smoothScroll = function (options) {
		var settings = $.extend({
			'speed': 400,
			'ease': 'swing',
			'offset': 0,
			'ignore': ''
		}, options);

		function scroller(settings, href, state) {
			$('.header').removeClass('is-active');
			$('.headerBusiness button').modaal('close');
			var target = $(href == "#" || href == "" ? 'html' : href);
			var position = target.offset().top + settings.offset;
			// var position = target.get(0).offsetTop + settings.offset;
			if (state == 'load') {
				$("html, body").scrollTop(position);
			} else if (state == 'click') {
				$("html, body").stop().animate({
					scrollTop: position
				}, settings.speed, settings.ease);
			}
		}

		var urlHash = location.hash;
		if (urlHash) {
			scroller(settings, urlHash, 'load');
		}

		$(this).on('click', function (event) {
			event.preventDefault();
			var $this = $(this);
			if (!$this.is(settings.ignore)) {
				var href = $this.attr("href");
				href = href.match(/#.*/g);
				href = href[0].replace(/\?.*/g, '');
				scroller(settings, href, 'click');
			}
		});
	};
})();
