$(() => {

	if ($('.js-slideToggle')[0]) {
		$('.js-slideToggle').each(function () {
			var $thisEl = $(this);
			$thisEl.children('.js-slideToggle-header').on('click', function (event) {
				event.preventDefault();
				if ($(this).hasClass('is-active')) {
					$thisEl.children('.js-slideToggle-header').removeClass('is-active');
					$thisEl.children('.js-slideToggle-body').removeClass('is-active').stop().slideUp(400);
				} else {
					$thisEl.children('.js-slideToggle-header').addClass('is-active');
					$thisEl.children('.js-slideToggle-body').addClass('is-active').stop().slideDown(400);
				}
			});
			$thisEl.children('.js-slideToggle-header').on('keydown', function (event) {
				if(event.keyCode === 13) {
					$(this).trigger('click');
				}
			});
		});
	}

});